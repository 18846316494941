import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from '@/config/axios';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: 'naverfed66f83781e1c2e61f64bec2e2020e6.html',
      beforeEnter() {
        window.location.href = '/naverfed66f83781e1c2e61f64bec2e2020e6.html';
      }
    },
    {
      path: '/',
      component: () => import('@/layout/BaseLayout.vue'),
      children: [
        {
          // 홈
          path: '',
          name: 'main',
          component: () => import('@/pages/main/Main.vue')
        },
        {
          // 리뷰 상세
          path: 'review/detail/:id',
          name: 'detailReview',
          component: () => import('@/pages/review/DetailReview.vue'),
          meta: {
            simpleLayout: true
          }
        },
        {
          // 매장 리뷰 리스트
          path: 'review/place/:placeId',
          name: 'placeReview',
          component: () => import('@/pages/review/PlaceReviewList.vue'),
          meta: {
            simpleLayout: true
          }
        }
      ]
    },
    {
      path: '',
      component: () => import('@/layout/SimpleLayout.vue'),
      children: [
        {
          path: '/system',
          name: 'system',
          component: () => import('@/pages/system/Index.vue')
        },
        {
          path: 'bicena',
          name: 'bicena',
          component: () => import('@/pages/place/Bicena.vue')
        },
        {
          path: 'place/:placeId',
          name: 'place',
          component: () => import('@/pages/place/Index.vue'),
          props: true
        },
        {
          path: 'BMW_LUXURYCLASS_DININGEVENT',
          name: 'bmw_custom',
          component: () => import('@/pages/place/Index.vue'),
          // 라우트 진입 전에 실행되는 가드
          beforeEnter: (to, from, next) => {
            // 원래의 URL은 유지하면서 컴포넌트에 placeId를 전달
            to.params.placeId = '51034';
            next();
          },
          // props를 동적으로 전달
          props: (route) => ({
            placeId: '51034'
            // isBMWEvent: true // 필요한 경우 추가 props 전달 가능
          })
        },
        // 매장 메뉴 페이지
        {
          path: '/place/:placeId/menu',
          name: 'placeMenu',
          component: () => import('@/pages/place/PlaceMenu.vue'),
          meta: {
            title: '메뉴',
            noneHeader: true,
            tab: '0'
          }
        },
        // 매장 공지사항 페이지
        {
          path: '/place/:placeId/notice',
          name: 'placeNotice',
          component: () => import('@/pages/place/PlaceNotice.vue'),
          meta: {
            title: '공지사항',
            noneHeader: true
          }
        },
        {
          path: '/payment/:cartId',
          name: 'payment',
          props: true,
          component: () => import('@/pages/payment/Index.vue'),
          meta: {
            noneHeader: true
          }
        },
        {
          path: '/payment/result/:type',
          name: 'paymentResult',
          props: true,
          component: () => import('@/pages/payment/PaymentResult.vue')
        },
        {
          // 스태틱 페이지
          path: '/static/:staticwebId',
          name: 'static',
          component: () => import('@/pages/static/Index.vue'),
          meta: {
            simpleLayout: true
          }
        },
        {
          // 배너 리스트
          path: '/banner/:type',
          name: 'banner',
          props: true,
          component: () => import('@/pages/main/DetailBanner.vue'),
          meta: {
            noneHeader: true,
            noneFooter: true
          },
          children: [
            {
              // 단일 배너
              path: '/banner/:type?id=:id'
            },
            {
              // 지역별 배너 그룹 더보기
              path: '/banner/:type?area=:area_id&sort=:sort'
            },
            {
              // 인기 지역별 배너 그룹 더보기
              path: '/banner/:type?popular_area=:area_id&sort=:sort'
            },
            {
              // 커스텀 배너 그룹 더보기
              path: '/banner/:type?area_id=:custom_area_id&id=:id'
            },
            {
              // 최근 본 매장 더보기
              path: '/interest_place',
              meta: {
                noneHeader: true,
                noneFooter: true
              }
            }
          ]
        },
        {
          // 공지사항
          path: '/notice',
          name: 'notice',
          component: () => import('@/pages/policy/Notice.vue')
        },
        {
          // 이용약관
          path: '/policy/terms',
          name: 'terms',
          component: () => import('@/pages/policy/Terms.vue')
        },
        {
          // 개인정보취급방침
          path: '/policy/privacy',
          name: 'privacy',
          component: () => import('@/pages/policy/Privacy.vue')
        },
        {
          // 제3자 마케팅 활용 동의
          path: '/policy/marketing',
          name: 'marketing',
          component: () => import('@/pages/policy/Marketing.vue')
        },
        {
          // 상품 사용 및 환불 규정
          path: '/policy/useRefund',
          name: 'useRefund',
          component: () => import('@/pages/policy/UseRefund.vue')
        },
        {
          // 개인정보 수집 및 이용 동의
          path: '/policy/collection',
          name: 'collection',
          component: () => import('@/pages/policy/Collection.vue')
        },
        {
          // 개인정보처리방침 변경 내용
          path: '/policy/diff',
          name: 'diff',
          component: () => import('@/pages/policy/Diff.vue')
        },
        {
          // 매장 리스트
          path: '/policy/store',
          name: 'store',
          component: () => import('@/pages/policy/Store.vue')
        },
        {
          // 메일인증 로그인 안내
          path: '/email/certify/login',
          name: 'emailCertifyLogin',
          component: () => import('@/pages/email/EmailCertifyLogin.vue')
        },
        {
          // 메일인증 회원가입 안내
          path: '/email/certify/signup',
          name: 'emailCertifySignup',
          component: () => import('@/pages/email/EmailCertifySignup.vue')
        },
        {
          // 메일인증 인증실패 팝업
          path: '/email/certify/fail',
          name: 'emailCertifyFail',
          component: () => import('@/pages/email/EmailCertifyFail.vue')
        },
        {
          // 비밀번호 재설정
          path: '/password/reset',
          name: 'passwordReset',
          component: () => import('@/pages/email/PasswordReset.vue')
        },
        {
          // 비밀번호 재설정 완료
          path: '/password/reset/final',
          name: 'passwordResetFinal',
          component: () => import('@/pages/email/PasswordResetFinal.vue')
        }
      ]
    },
    {
      path: '/product/:productId', // 기존 티켓 링크로 접근 시 매장 상세로 이동
      name: 'product',
      beforeEnter: (to, from, next) => {
        const productId = to.params.productId;
        axios
          .get(`/product/${productId}`)
          .then((response) => {
            if (response.data.status === false) {
              next('main');
            }
            const placeId = response.data.data.place_id;
            router.replace(`/place/${placeId}`);
          })
          .catch((error) => {
            next('main');
          });
      }
    },
    {
      path: '*', // 정의되지 않은 모든 URL 홈으로 이동
      redirect: { name: 'main' }
    }
  ]
});

export default router;
